import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User2/Documents/flits-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "sales-order"
    }}>{`Sales Order`}</h1>
    <p>{`Sales Order Form adalah formulir yang digunakan untuk mencatat pesanan yang diterima oleh Customer. Pada Flits, Sales Order (SO) dapat dibuat satu per satu atau langsung membuat beberapa Order dalam sekali proses.`}</p>
    <h2 {...{
      "id": "formulir-sales-order"
    }}>{`Formulir Sales Order`}</h2>
    <img src='/public/img/SO/Form SO.png' />
    <p>{`Kolom-kolom Pada Formulir Sales Order`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Nama`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memilih  Customer yang melakukan pemesanan Jasa. Terdapat dua field lain yang saling bergantung, yakni Rute dan Item.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Kendaraan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memilih Kendaraan yang akan digunakan untuk mengantarkan pesanan.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Supir`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memilih Supir yang akan mengantarkan pesanan kepada Customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`No. Telp. Supir`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk melihat nomor telepon supir yang dipilih. Field ini otomatis terisi setelah Supir telah dipilih.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl SO`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk mengisi tanggal dari SO, secara default akan terisi “hari ini”.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Est Sampai Pengiriman`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk mengisi tanggal perkiraan, secara default akan terisi “hari ini”.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Rute`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memilih Rute pengiriman SO, List yang muncul pada field ini, diambil sesuai settingan rute yang ada pada Master Customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Premi Supir`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan uang bayaran kepada Supir, Secara default akan mengkalkulasi sesuai jumlah barang yang dipesan. Namun untuk beberapa rule dapat mengubahnya.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "detail-tabel-sales-order"
    }}>{`Detail Tabel Sales Order`}</h3>
    <p>{`Pada bagian detail Sales Order ini terdapat dua bagian, yakni :`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tab Pricing Item`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Berisikan list item yang dapat dikirim berdasarkan rute yang telah ditentukan sebelumnya. List item yang dapat dipilih sebelumnya harus di setting terlebih dahulu pada Master Customer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Ordered Qty`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan jumlah barang yang akan dikirim / dipesan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Unit`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Merupakan satuan ukur setiap item yang dipilih. Field ini otomatis terisi ketika sudah memilih item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Unit Price`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Berisi pilihan harga untuk setiap unit item. Pengaturan harga tiap item dilakukan pada Master Customer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Subtotal`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field perhitungan antara Jumlah Order dikali dengan Unit Price`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "tab-operational"
    }}>{`Tab Operational`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Trip`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Otomatis terisi sesuai rute yang telah dipilih, namun partisi untuk setiap tujuannya`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`DO #`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan Nomor Delivery Order untuk setiap tujuan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Job Start Date`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan tanggal dimulaikan pengiriman ke tujuan tertentu pada Sales Order tersebut (Dapat terisi otomatis apabila memasukkan Pick Up Qty terlebih dahulu)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Job Finished Date`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan tanggal selesainya pengiriman ke tujuan tertentu pada Sales Order tersebut (Dapat terisi otomatis apabila memasukkan Drop Off Qty terlebih dahulu)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pick Up Qty`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan jumlah barang yang diambil oleh Supir pada kota asal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Drop Off Qty`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan jumlah barang yang dikirimkan oleh Supir pada kota tujuan`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "informasi-lainnya-pada-formulir-sales-order-detail"
    }}>{`Informasi Lainnya Pada Formulir Sales Order Detail`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Deskripsi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk mengisi keterangan atasan pesanan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Subtotal`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Otomatis terisi sesuai total amount sebelum pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pajak`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambahkan Pajak pada pesanan oleh Customer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Total`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Otomatis terisi sesuai total amount setelah ditambahkan pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Witholding`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan Jumlah DP`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tab Delivery Order`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pada tab ini berisi laporan Surat Jalan dan Tanda terima yang telah dikirimkan oleh Supir atas SO tersebut`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tab Log`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pada tab ini berisikan aktivitas - aktivitas yang telah dilakukan pada dokumen Sales Order tersebut oleh yang bersangkutan`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "list-sales-order"
    }}>{`List Sales Order`}</h2>
    <p>{`Dokumen yang telah dibuat pada database, akan disimpan atau direkap dalam satu tabel yang disebut List Sales Order. List ini juga bisa dikatakan sebagai Overview daripada Sales Order`}</p>
    <p>{`List Sales Order dapat diakses dengan cara klik Order Pada `}<strong parentName="p">{`Side Menu > Order`}</strong></p>
    <img src='/public/img/SO/List SO.png' />
    <p>{`Tampilan List Sales Order terbagi menjadi dua bagian yaitu :`}</p>
    <h3 {...{
      "id": "list-sales-order-1"
    }}>{`List Sales Order`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Buat`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tombol untuk membuat dokumen SO baru`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Import`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tombol untuk menginputkan dokumen SO secara massal dalam sekali import`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Filter`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Terdapat dua jenis filter yaitu by Status dan by Periode`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Search bar`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field yang dapat digunakan untuk melakukan pencarian terhadap dokumen yang diinginkan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Atur Halaman`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Navigasi Next or Previous Page`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "list-sales-order-detail"
    }}>{`List Sales Order Detail`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`SO#`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nomor Sales Order`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Customer`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nama pelanggan yang melakukan pemesanan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl SO`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tanggal Sales Order`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Kendaraan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nomor Polisi kendaraan yang digunakan untuk mengirim pesanan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Supir`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nama Supir yang bertugas sebagai kurir`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Rute`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rute Pengiriman barang`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Job Start/End Date`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tanggal Mulai dan Selesainya pengiriman`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Premi Supir`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jumlah uang yang diberikan kepada supir`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Total`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Harga yang harus dibayar oleh pelanggan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Status`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Terdiri dari tujuh status, yakni :`}<ul><li><strong parentName="td">{`Draft`}</strong><div>{`Dokumen masih berupa quotation`}</div></li><li><strong parentName="td">{`Open`}</strong><div>{`Dokumen yang sudah dilakukan Approval`}</div></li><li><strong parentName="td">{`Job Started`}</strong><div>{`Dokumen Sedang dalam perjalanan`}</div></li><li><strong parentName="td">{`To Be Invoiced`}</strong><div>{`Dokumen telah selesai dikirim dan dapat ditagihkan pada Customer`}</div></li><li><strong parentName="td">{`Invoiced`}</strong><div>{`Dokumen sudah dibuatkan tagihan`}</div></li><li><strong parentName="td">{`Paid`}</strong><div>{`Sales Order telah dilakukan pembayaran`}</div></li><li><strong parentName="td">{`Cancelled`}</strong><div>{`Dokumen dibatalkan setelah dilakukan Approval`}</div></li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Info tambahan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`terdapat tiga ikon tambahan`}<ul><li><strong parentName="td">{`Ikon Warning ( `}<img src='/public/img/SO/warning.png' style={{
                    "margin": "0"
                  }} />{` )`}</strong><div>{`Ikon tersebut muncul ketika pengiriman mengalami keterlambatan`}</div></li><li><strong parentName="td">{`Ikon Attachment ( `}<img src='/public/img/SO/attachment biru.png' style={{
                    "margin": "0"
                  }} />{` )`}</strong><div>{`Ikon terrsebut muncul ketika supir telah mengirimkan bukti Surat Jalan`}</div></li><li><strong parentName="td">{`Ikon Attachment ( `}<img src='/public/img/SO/attachment hijau.png' style={{
                    "margin": "0"
                  }} />{` )`}</strong><div>{`Ikon terrsebut muncul ketika supir telah mengirimkan bukti Tanda Terima`}</div></li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "membuat-sales-order"
    }}>{`Membuat Sales Order`}</h2>
    <p>{`Berikut merupakan langkah-langkah dalam pembuatan dokumen Sales Order yang dapat digunakan sebagai pencataan penjualan :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Sales Order dengan klik Order Pada `}<strong parentName="p">{`Side Menu > Order > Pada list Sales Order klik Tombol Create`}</strong>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Isi Formulir Sales Order seperti gambar berikut :`}</p>
  <img src='/public/img/SO/Bikin SO.png' />
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Pilih Nama Customer`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Isi Tanggal SO dan Tanggal Estimasi barang sampai tujuan`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih Kendaraan yang “Available” beserta dengan Supirnya`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Tentukan Rute Pengiriman nya. Perlu dicatat list Rute yang muncul adalah sesuai dengan yang ada pada Master Customer. `}</p>
            <img src='/public/img/SO/Bikin SO Detail.png' />
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pada Tab Pricing, Pilih item yang akan dikirimkan beserta jumlah barang dan harga setiap unitnya sesuai dengan pesanan Customer. Sama dengan Rute, list item yang muncul dapat disetting pada Master Customer`}</p>
            <img src='/public/img/SO/Bikin SO Detail 2.png' />
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pada Tab Operational, Dapat dimasukkan Nomor DO untuk setiap Surat Jalan yang diberikan kepada Supir`}</p>
            <p parentName="li">{`NB : Untuk kolom Job Start Date, Job Finished Date, Pick Up Qty dan Drop Off Qty dapat diisi ketika status dokumen “Open”`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setelah semua field diisi sesuai dengan pesanan, klik tombol Save untuk menyimpan dokumen penjualan yang telah diisi sebelumnya. Pada saat ini status Dokumen adalah “Draft”. (Dokumen masih perlu Approval untuk dapat dilanjutkan ke langkah berikutnya)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Untuk melakukan Approval user dapat klik tombol Confirm pada halaman detail dokumen. Dengan ini status dokumen Sales Order menjadi “Open”.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "memperbarui-status-dokumen-so"
    }}>{`Memperbarui Status Dokumen SO`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Open > Job Started`}</strong></p>
        <p parentName="li">{`  Status Job Started menandakan bahwa SO tersebut sedang dalam perjalanan menuju lokasi tujuan. Pada perubahan status ini, akan otomatis membuat dokumen Driver Settlement. `}</p>
        <p parentName="li">{`  Berikut langkah untuk memperbarui status SO :`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Klik Tombol Input Pick Up Qty`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Jumlah barang yang diambil oleh Supir pada tab Operasional di kolom Pick-Up Qty`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Ketika sudah memasukkan Jumlah barang yang diambil kemudian klik tombol Save`}</p>
            <img src='/public/img/SO/SO Open - Jobstarted.png' />
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Job Started > To Be Invoiced`}</strong></p>
        <p parentName="li">{`  Status To Be Invoiced menandakan bahwa SO tersebut telah sampai pada lokasi tujuan. Sehingga SO tersebut dapat dibuatkan tagihan (Customer Invoice) untuk segera dilakukan pembayaran`}</p>
        <p parentName="li">{`  Berikut langkah untuk memperbarui status SO menjadi To Be Invoiced :`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Klik Tombol Input Drop Off Qty`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Jumlah barang yang diambil oleh Supir pada tab Operasional di kolom Drop Off Qty`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Ketika sudah memasukkan Jumlah barang yang terkirim kemudian klik tombol Save`}</p>
            <img src='/public/img/SO/SO Jobstarted - Tobe Invoiced.png' />
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`To Be Invoiced > Invoiced`}</strong></p>
        <p parentName="li">{`  Status Invoiced menandakan bahwa SO tersebut telah dibuatkan dokumen Customer Invoice. Perubahan status ini hanya dapat dilakukan dengan membuatkan Invoice untuk SO tersebut kemudian Invoicenya di Konfirmasi.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Invoiced > Paid`}</strong></p>
        <p parentName="li">{`  Status Paid menandakan bahwa SO tersebut telah selesai dilakukan pembayaran. Perubahan status ini hanya dapat dilakukan dengan melakukan pelunasan pada Invoice yang mengandung SO tersebut.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Membatalkan Dokumen Sales Order`}</strong></p>
        <p parentName="li">{`  Dokumen Sales Order dapat dibatalkan (Cancel) saat status Sales Order adalah Open, Job Started atau To Be Invoiced. Cara melakukan pembatalannya dengan Klik Dropdown Action pada detail dokumen `}<strong parentName="p">{`SO > Klik Cancel`}</strong></p>
  <img src='/public/img/SO/Cancel SO.png' />
      </li>
    </ul>
    <h2 {...{
      "id": "memantau-aktivitas-supir"
    }}>{`Memantau Aktivitas Supir`}</h2>
    <p>{`Pada bagian bawah dokumen Sales Order ini terdapat tab “Delivery Order”, pada tab tersebut akan terekam Bukti dokumen surat jalan maupun tanda terima dari supir yang ditugaskan mengantarkan Order tersebut. `}</p>
    <img src='/public/img/SO/Delivery Order.png' />
    <h2 {...{
      "id": "buat-order-massal"
    }}>{`Buat Order Massal`}</h2>
    <p>{`Menu ini berguna untuk membuat dokumen Sales Order atas nama satu Customer, kemudian dapat dibuatkan lebih dari satu Order. `}</p>
    <p>{`Berikut merupakan langkah-langkah dalam pembuatan dokumen Sales Order melalui Fitur Buat Order Massal :`}</p>
    <ol>
      <li parentName="ol">{`Klik `}<strong parentName="li">{`Order`}</strong>{` yang ada pada `}<strong parentName="li">{`side menu > Buat Order Massal`}</strong></li>
      <li parentName="ol">{`Pilih Nama Customer`}</li>
      <li parentName="ol">{`Isi Field Pada Tabel detail secara lengkap (satu baris menandakan satu dokumen Sales Order)`}</li>
      <li parentName="ol">{`Setelah semua field pada satu baris terisi klik tombol Centang`}</li>
      <li parentName="ol">{`Klik tombol Save`}</li>
    </ol>
    <img src='/public/img/SO/Buat Order Massal.png' />
	
Setelah itu halaman akan terdireksi ke List Sales Order, kemudian SO yang terbentuk semuanya berstatus Draft.

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      